<template>
  <module-container
    route-name="accountconfigs"
    show-toolbar
    :title="$t('accountconfigs')"
  >
    <template #route>
      <router-view :key="$route.fullPath" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component
export default class AccountconfigsIndex extends Mixins(
  PaginateProviderMixin
) {}
</script>
